
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { Ticket } from "@/models/tickets";
  import { CollectionResourceDoc } from '@/models/jsonapi'

  export default defineComponent({
    setup(_) {
      const root = getCurrentInstance().proxy
      const dbData = computed<CollectionResourceDoc<string, Ticket>>(() => root.$store.state.tickets.data)

      root.$store.dispatch('tickets/load')

      const columns = ref([
          {
            component: () => import("@/components/shared/table/cells/LinkCell.vue"),
            type: "Def",
            key: "id",
            title: "ID",
            sortable: true,
            options: {
              contentFunction: (data:any) => data.item.address.fullName,
              hrefFunction: (data: any) => `/tickets/${data.item.id}`
            }
          },
        ]
      )

      return {
        dbData,
        columns
      }
    }
  })
